/*
|-----------------------------------------
| comment
| @author: jahid haque <jahid.haque@yahoo.com>
| @copyright: company, 2020
|-----------------------------------------
*/


import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Nav from './nav/nav';
import Footer from './pages/footer';


const Home = lazy(() => import('./pages/home'));
const Price = lazy(() => import('./pages/price'));
const Resources = lazy(() => import('./pages/resources'));
const Booking = lazy(() => import('./pages/booking'));
const Testimonial = lazy(() => import('./pages/testimonial'));
const Contact = lazy(() => import('./pages/contact'));

const App = () => {

  const history = createBrowserHistory();

  return (
    <React.Fragment>
      <Nav />
      <Router history={history}>
        <Suspense fallback={<span className={'mx-auto w-50 d-block'}>Loading....</span>}>
          <Switch>
            <Route exact path={'/'} component={Home} />
            <Route path={'/price'} component={Price} />
            <Route path={'/learnerresources'} component={Resources} />
            <Route path={'/bookalesson'} component={Booking} />
            <Route path={'/testimonial'} component={Testimonial} />
            <Route path={'/contact'} component={Contact} />
          </Switch>
        </Suspense>
      </Router>

      <Footer />
    </React.Fragment>
  )
}

export default App;
