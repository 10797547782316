/*
|-----------------------------------------
| setting up navigation ui for the app
| @author: jahid haque <jahid.haque@yahoo.com>
| @copyright: autodrive, 2020
|-----------------------------------------
*/

import React from 'react';

const Nav = () => {
    return (
        <header role="banner" itemscope="" itemtype="https://schema.org/WPHeader">
            <div className="container">
                <nav className = 'sticky-top navbar navbar-expand-lg navbar-light rounded bg-transparent'
                    role="navigation" itemScope itemType='https://schema.org/SiteNavigationElement'>
                    <h1 className = 'd-none'>Site navigation</h1>
                    <a className="navbar-brand" href="/">
                        <img src = '/img/logo.jpg'
                            alt = 'logo'
                            width = '60'
                            height = '60'
                            className = 'img-fluid d-inline-block' />
                    </a>
                    <button className="navbar-toggler" type="button" 
                        data-toggle="collapse" data-target="#autoDriveMenu" 
                        aria-controls="autoDriveMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className = {'collapse navbar-collapse'} id ='autoDriveMenu'>
                        <ul className = 'navbar-nav mr-3'>
                            <li className="nav-item"><a href="/price" className="nav-link">Price</a></li>
                            <li className="nav-item"><a href="/learnerresources" className="nav-link">Learner Resources</a></li>
                            <li className="nav-item"><a href="/bookalesson" className="nav-link">Book a Lesson</a></li>
                            <li className="nav-item"><a href="/testimonial" className="nav-link">Testimonial</a></li>
                            <li className="nav-item"><a href="/contact" className="nav-link">Contact</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>

    )
}

export default Nav;
