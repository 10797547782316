/*
|-----------------------------------------
| setting up footer ui
| @author: jahid haque <jahid.haque@yahoo.com>
| @copyright: mealnight, 2020
|-----------------------------------------
*/

import React from 'react'


const Footer = () => {
    return (
        <div className = 'footer'>
            <div className = 'container'>
                <p className="mb-0 text-center f-14 text-secondary">All &copy; right reserved, 2018</p>
            </div>
        </div>
    )
}

export default Footer;
